import React from "react";
import { GrSystem } from "react-icons/gr";
import { FaNetworkWired } from "react-icons/fa";
import { LuBrainCog } from "react-icons/lu";
import { FaSearchengin, FaPeopleGroup } from "react-icons/fa6";
import { MdOutlineContactSupport } from "react-icons/md";
import { Slide } from "react-awesome-reveal";

function SellingPoints() {
  const icon_class = "text-5xl text-white";
  const points = [
    {
      icon: <GrSystem className={icon_class} />,
      title: "System up-time",
      description:
        "Through our real time system performance tracker, JEE STREAMS is able to collect and address the transaction performance issues, we are able to learn about the delays on the go, making it easy for our system admins to fix any prevailing challenges.",
    },
    {
      icon: <FaNetworkWired className={icon_class} />,
      title: "Cross network support",
      description:
        "It is well thought that our short-code supports transaction across all networks where by a customer can buy Lyca Mobile data using their MTN Mobile money. This has been one of the game changing initiatives aimed at making customers' lives easy.",
    },
    {
      icon: <LuBrainCog className={icon_class} />,
      title: "Innovativeness",
      description:
        "Keeping pace with trending innovations is key for anyone who wants to craft their unique selling proposition, JEE STREAMS is at the forefront of every breakthrough innovations around the world of e-Commerce.",
    },
    {
      icon: <FaSearchengin className={icon_class} />,
      title: "Research",
      description:
        "At the forefront of every successful strategy is research. We have preeminent team of subject matter experts whose core role is to study the evolving customer needs, extract value from every important information that we later on deploy for the benefit of our esteemed clients, making their mobile transaction experience exceptional.",
    },
    {
      icon: <MdOutlineContactSupport className={icon_class} />,
      title: "Customers support",
      description:
        "Our open-door policy is the reason our clients find our services good. We always help clients address any query regarding our services on the go. Through this approach, all clients find us the number one destination for reliable short codes",
    },
    {
      icon: <FaPeopleGroup className={icon_class} />,
      title: "Multiple partners",
      description:
        "As part of our goals to provide seamless transaction options to our target audience, we are in partnership with all the telecommunication networks making it easy for our esteemed clients to carry out transaction from Lyca Mobile to all other networks through our USSD code popularly known as “217*402#” which is followed by other prompts.",
    },
  ];
  return (
    <div className="py-20">
      <h2 className="pb-10 text-5xl font-semibold text-center border-b green-color">
        Our Unique Selling points
      </h2>

      <div className="grid gap-10 mt-16 md:grid-cols-2 lg:grid-cols-3">
        <Slide triggerOnce direction="up">
          {points.map((p, i) => (
            <div key={i} className="">
              <div className="flex justify-center">
                <div className="p-6 rounded-full green-gradient-bg">{p.icon}</div>
              </div>
              <p className="my-3 font-[550] text-center">{p.title}</p>
              <p className="">{p.description}</p>
            </div>
          ))}
        </Slide>
      </div>
    </div>
  );
}

export default SellingPoints;
