import React from "react";

import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { google_map_api } from "../../utils/utils";

function Location() {
  const [libraries] = React.useState(["places"]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: google_map_api,
    libraries: libraries,
  });

  const center = {
    lat: 0.3431621926519102,
    lng: 32.592209885589675,
  };

  return (
    <div>
      {isLoaded && (
        <GoogleMap
          zoom={15}
          center={center}
          mapContainerStyle={{ width: "100%", height: "450px" }}
        >
          <Marker shape="MarkerShapePoly" cursor="pointer" position={center} />
        </GoogleMap>
      )}
    </div>
  );
}

export default Location;
