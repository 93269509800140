import React from "react";

import phone from "../../assets/images/phone.png";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin, FaSquareXTwitter, FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
import { social_links } from "../../utils/utils";
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";

function TopContent() {
  const icon_class = "text-2xl";
  const socials = [
    {
      icon: <FaFacebookSquare className={icon_class} />,
      url: social_links.facebook,
    },
    {
      icon: <FaLinkedin className={icon_class} />,
      url: social_links.linked_in,
    },
    {
      icon: <FaSquareXTwitter className={icon_class} />,
      url: social_links.twitter,
    },
    {
      icon: <FaInstagram className={icon_class} />,
      url: social_links.instagram,
    },
    { icon: <MdOutlineMail className={icon_class} />, url: social_links.email },
    { icon: <FaWhatsapp className={icon_class} />, url: social_links.phone },
  ];
  return (
    <div className="out-box">
    <div className="landing-bg px-20">
    <div className="flex items-center text-white px-10 pt-10 md:pt-24 pb-24 md:pb-44">
      <div className="mx-auto container-box lg:flex">
        <div className="items-center min-w-20 lg:flex">
          <Slide triggerOnce direction="up">
            <div className="flex flex-row justify-center gap-6 pb-3 border-b border-dashed lg:border-b-0 lg:border-r lg:pb-0 lg:pr-6 lg:flex-col">
              {socials.map((media, i) => (
                <a href={media.url} target="_blank" rel="noreferrer" key={i}>
                  {media.icon}
                </a>
              ))}
            </div>
          </Slide>
        </div>
        <div className="items-center w-full mt-10 gap-14 flex flex-col md:flex-row lg:mt-0">
          <div className="w-full md:w-2/3">
            <Fade triggerOnce>
              <h1 className="mb-10 text-3xl font-semibold leading-snug md:text-4xl">
                <span className="text-4xl md:text-5xl font-bold pb-2">
                JEE Streams Investments Limited
                </span>
              </h1>

              <p className="my-4 text-lg md:text-xl">
                The speed of technological evolution and ground breaking
                innovations demands that every business, small or big, embraces
                technology to create value for their businesses.
              </p>
              <p className="text-lg md:text-xl">
                Owing to this fact, a secure intermediation during the interaction
                between these parties is vital and urgently needed now!
              </p>

              <div className="flex">
                <Link to="/about">
                  <p className="bg-white font-[550] mt-10 text-blue-800 px-14 py-2.5 rounded-lg">
                    Learn More
                  </p>
                </Link>
              </div>
            </Fade>
          </div>
          <div className="w-full md:w-1/3 flex justify-center">
            <Fade triggerOnce>
              <img src={phone} alt="*217*402#" className="max-h-96" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default TopContent;
