import React from "react";
import { Slide } from "react-awesome-reveal";
import { FaGlobeAfrica } from "react-icons/fa";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";

function GetInTouch() {
  const icon_class = "text-6xl";
  const contacts = [
    {
      icon: <FaGlobeAfrica className={icon_class} />,
      title: "Address",
      detail: (
        <p>
          Ruthent Tower, 4th Floor, Plot 96A - Bukoto street, Kampala, Uganda
        </p>
      ),
    },
    {
      icon: <IoPhonePortraitOutline className={icon_class} />,
      title: "Call Us",
      detail: <a href="tel:+256200902247">+256 20 0902247</a>,
    },
    {
      icon: <MdOutlineMailOutline className={icon_class} />,
      title: "Email",
      detail: (
        <a href="mailto:helpdesk@jeestreams.com">helpdesk@jeestreams.com</a>
      ),
    },
  ];
  return (
    <div className="main-bg">
      <div className="flex flex-col gap-12 mx-auto text-white lg:flex-row py-28 container-box">
        <div className="w-full lg:w-1/3">
          <h3 className="text-5xl">
            Get in <span className="font-semibold green-color">Touch</span>
          </h3>
          <p className="my-6 text-lg leading-loose">
            Come and visit our quarters or simply send us an email anytime you
            want. We are open to all suggestions from our audience.
          </p>
        </div>
        <div className="grid w-full gap-8 md:grid-cols-3 lg:w-2/3">
          <Slide triggerOnce direction="right">
            {contacts.map((contact, i) => (
              <div key={i} className="text-center">
                <div className="flex justify-center">{contact.icon}</div>
                <h4 className="mt-6 mb-3 font-semibold green-color">
                  {contact.title}
                </h4>
                {contact.detail}
              </div>
            ))}
          </Slide>
        </div>
      </div>
    </div>
  );
}

export default GetInTouch;
