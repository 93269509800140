import React from "react";
import { Parallax } from "react-parallax";
import service from "../../assets/images/services-bg.webp";
import payment from "../../assets/images/payment.png";
import mobile_money from "../../assets/images/mobile_money.png";
import lyca from "../../assets/images/lyca.webp";
import airtel from "../../assets/images/airtel.webp";
import mtn from "../../assets/images/mtn.webp";
import utl from "../../assets/images/utl.webp";
import ussd_code from "../../assets/images/ussd_code.webp";
import SellingPoints from "./selling-points";
import { Helmet } from "react-helmet";
import { Fade, Slide } from "react-awesome-reveal";

function Services() {
  const networks = [airtel, mtn, utl];
  const page = {
    title: "Our services",
    description: "Elevate your digital experience with our comprehensive IT services. From seamless infrastructure management to cutting-edge cybersecurity solutions, we offer a suite of services tailored to meet the dynamic needs of your business."
  }

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <Parallax
        blur={2}
        bgImage={service}
        bgImageAlt="Testimonials"
        strength={300}
      >
        <Slide triggerOnce direction="left">
          <div className="py-16 mx-auto md:py-24 container-box">
            <div className="max-w-2xl px-4">
              <h2 className="text-3xl md:text-5xl font-[300]">
                Experience efficiency, security, and innovation
              </h2>
              <p className="mt-2 text-3xl md:text-5xl font-[550] green-color">
                with our IT services
              </p>
            </div>
          </div>
        </Slide>
      </Parallax>

      <div className="main-bg">
        <div className="max-w-4xl px-4 py-20 mx-auto text-lg text-center text-white md:text-2xl ">
          <p>
            Elevate your digital experience with our comprehensive IT services.
            From seamless infrastructure management to cutting-edge
            cybersecurity solutions, we offer a suite of services tailored to
            meet the dynamic needs of your business.
          </p>
        </div>
      </div>

      <div className="mx-auto container-box">
        <div
          className="flex flex-col items-center gap-12 py-16 md:flex-row"
          id="payments"
        >
          <div className="w-full text-lg">
            <Slide triggerOnce direction="up">
              <h2 className="text-2xl md:text-4xl font-[550] my-6">
                Integrated payment and merchant solutions
              </h2>
              <p className="menu-color">
                We offer a unique integrated and Mobile Money solutions. We have
                partnered with top local telecommunication companies (MTN, Airtel,
                LYCA Mobile and UeTL) to bring you an all-in-one E-commerce
                Payment Gateway. Our unique gateway allows for online payments
                using short links and Mobile Money.
              </p>
              <div className="flex gap-6 mt-3">
                {networks.map((n, i) => (
                  <img
                    src={n}
                    key={i}
                    alt=""
                    className="object-cover rounded-md h-11"
                  />
                ))}
              </div>
            </Slide>
          </div>
          <div className="flex justify-center w-full">
            <Fade>
              <img src={payment} alt="" className="max-h-80" />
            </Fade>
          </div>
        </div>

        <div
          className="flex flex-col items-center gap-12 py-10 md:flex-row-reverse"
          id="mobile-money"
        >
          <div className="w-full text-lg">
            <Slide triggerOnce direction="right">
              <h2 className="text-2xl md:text-4xl font-[550] my-6">
                Mobile money aggregation
              </h2>
              <p className="menu-color">
                At JEE Streams, we offer both mobile money Integrating and
                aggregating to ensure that our target audience achieve an
                efficient mobile transaction as and when they need it. We work
                hand in hand with the existing telecommunication companies to
                ensure that mobile money transactions yield the expectations.
              </p>
              <p className="mt-4 menu-color">
                Our comprehensive solution supports mobile money transactions
                through USSD. Our platform offers interoperability, making it easy
                to transfer mobile money between different telecom companies. With
                our APIs, you can integrate our services into your existing
                systems or use our in-house platforms for easy access. Join our
                growing list of partners and experience the benefits of our
                efficient and seamless mobile money aggregation and integration
                solution today!
              </p>
            </Slide>
          </div>
          <div className="flex justify-center w-full ">
            <Fade>
              <img src={mobile_money} alt="" className="max-h-80" />
            </Fade>
          </div>
        </div>

        <div
          className="flex flex-col items-center gap-12 py-10 md:flex-row"
          id="mobile-money"
        >
          <div className="w-full text-lg">
            <Slide triggerOnce direction="left" >
              <h2 className="text-2xl md:text-4xl font-[550] my-6">USSD codes</h2>
              <p className="menu-color">
                We provide USSD solutions to businesses, including aggregating
                USSD traffic, hosting partners on our short code.
              </p>
              <p className="mt-4 menu-color">
                We offer a range of solutions to businesses seeking to provide
                their services to customers via USSD. Our services include;
                aggregating USSD traffic from telecoms for business who own their
                own USSD short code, and hosting partners on our USSD short code.
                Through our short USSD Stream{" "}
                <span className="text-black font-[550]">*217*402#</span>, we are
                sole distributors for{" "}
                <span className="text-black font-[550]">
                  LYCA Mobile telecom{" "}
                </span>
                where customers are able to conveniently, and reliably purchase
                <span className="text-black font-[550]">
                  LYCA Mobile airtime and data bundles using MTN and Airtel Mobile
                  Money
                </span>
              </p>

              <p className="mt-4 menu-color">
                Our partners are able to offer a variety of services to their
                customers through USSD, including utility payments, mobile money
                collections and payouts, mobile savings and loans, and mobile
                banking. We offer comprehensive USSD aggregation services that
                ensure your customers can access your services easily and
                efficiently.
              </p>
            </Slide>
          </div>
          <div className="justify-center w-full ">
            <Fade>
              <img src={lyca} alt="" className="h-10 mx-auto" />
              <img src={ussd_code} alt="" className="mx-auto mt-4 max-h-80" />

              <div className="flex justify-center gap-6 mt-6">
                {networks.map((n, i) => (
                  <img
                    src={n}
                    key={i}
                    alt=""
                    className="object-cover rounded-md h-11"
                  />
                ))}
              </div>
            </Fade>
          </div>
        </div>

        <SellingPoints />
      </div>
    </div>
  );
}

export default Services;
