import React from "react";
import { Parallax } from "react-parallax";
import desk from "../../assets/images/desk.webp";
import { Avatar, Carousel } from "antd";
import avatar from "../../assets/images/avatar.webp";
import { Slide } from "react-awesome-reveal";

function Testimonial() {
  const testimonials = [
    {
      photo: "",
      title: "Incredibly User-Friendly",
      message:
        "We had the pleasure of working with JEE Streams and the experience has been nothing short of extraordinary. From the initial consultation to the final delivery, every aspect of our collaboration was marked by professionalism, innovation, and a genuine commitment to excellence.",
    },
    {
      photo: "",
      title: "Best work experience",
      message:
        "JEE Streams not only met but exceeded our expectations, delivering a product/service that not only addressed our specific needs but also showcased their deep understanding of industry trends and cutting-edge solutions. The level of expertise and dedication demonstrated by their team was truly impressive.",
    },
  ];
  return (
    <Parallax
      //   blur={{ min: -10, max: 20 }}
      bgImage={desk}
      bgImageAlt="Testimonials"
      strength={-200}
    >
      <Slide triggerOnce direction="up">
        <div className="pb-24 mx-auto pt-28 container-box">
          <h2 className="text-4xl md:text-5xl font-[300] text-center">
            Awesome client
          </h2>
          <p className="my-3 mb-10 text-4xl font-bold text-center green-color md:text-5xl">
            Reviews & Comments
          </p>
          <Carousel autoplay>
            {testimonials.map((testimonial, i) => (
              <div
                key={i}
                className="max-w-4xl pb-10 mx-auto text-lg testimonial"
              >
                <Avatar src={avatar} size={120} style={{ minWidth: 120 }} />
                <div className="mt-5">
                  <h3 className="mb-2 font-bold">{testimonial.title}</h3>
                  <p>{testimonial.message}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </Slide>
    </Parallax>
  );
}

export default Testimonial;
