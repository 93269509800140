import React from "react";
import { FaRegCopyright } from "react-icons/fa6";
import logo from "../assets/images/logo-detail.png";
import { Link } from "react-router-dom";
import { social_links } from "../utils/utils";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin, FaSquareXTwitter, FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";

function Footer() {
  const details = [
    {
      title: "Company",
      items: [
        { label: "Home", url: "" },
        { label: "About us", url: "about" },
        { label: "Contact us", url: "contact" },
        { label: "Our services", url: "services" },
      ],
    },
    {
      title: "Services",
      items: [
        { label: "Integrated payment and merchant solutions", url: "services#payments" },
        { label: "Mobile Money Aggregation", url: "services#mobile-money" },
        { label: "USSD code", url: "services#ussd" },
      ],
    },
  ];

  const icon_class = "text-2xl";
  const socials = [
    {
      icon: <FaFacebookSquare className={icon_class} />,
      url: social_links.facebook,
    },
    {
      icon: <FaLinkedin className={icon_class} />,
      url: social_links.linked_in,
    },
    {
      icon: <FaSquareXTwitter className={icon_class} />,
      url: social_links.twitter,
    },
    {
      icon: <FaInstagram className={icon_class} />,
      url: social_links.instagram,
    },
    { icon: <MdOutlineMail className={icon_class} />, url: social_links.email },
    { icon: <FaWhatsapp className={icon_class} />, url: social_links.phone },
  ];
  return (
    <div className="footer-color footer-bg">
      <div className="flex flex-col gap-16 pt-20 pb-16 mx-auto md:flex-row container-box">
        <div className="w-full text-white md:w-2/5">
          <img src={logo} alt="Jeestreams" className="h-16 mb-6" />
          <p className="leading-7">
            JEE Streams Investments Ltd is one of the kind legally registered
            business entity optimistic about bringing secure transaction into
            fruition. We are a registered Ugandan Company under the company
            registrations Act, 2012 of the republic of Uganda.
          </p>

          <div className="flex gap-4 mt-4">
            {socials.map((media, i) => (
              <a href={media.url} target="_blank" rel="noreferrer" key={i}>
                {media.icon}
              </a>
            ))}
          </div>
        </div>
        <div className="grid w-full sm:grid-cols-2 md:w-3/5">
          {details.map((d, i) => (
            <div key={i}>
              <h3 className="my-4 font-[550] text-lg text-white">{d.title}</h3>
              {d.items.map((items, k) => (
                <Link to={`/${items.url}`}>
                  <p className="my-2 text-[0.96rem]">{items.label}</p>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center gap-2 p-8 text-sm footer-border">
        <FaRegCopyright />
        <p>
          {new Date().getFullYear()} JEE Streams Investments Limited, All rights
          reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
