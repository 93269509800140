import React from "react";
import who_we_are from "../../assets/images/who.png";

function AboutCompany() {
  return (
    <div className="flex flex-col-reverse gap-10 py-20 mx-auto md:flex-row container-box">
      <div className="w-full md:w-1/3">
        <img src={who_we_are} alt="" className="sticky top-20" />
      </div>
      <div className="w-full text-lg md:w-2/3 menu-color">
        <h1 className="my-4 text-4xl font-semibold">
          Who is JEE Streams Investments Ltd?
        </h1>

        <p>
          <span className="font-[550] text-black">
            JEE STREAMS INVESTMENTS LTD
          </span>{" "}
          is one of the kind legally registered business entity optimistic about
          bringing secure transaction into fruition. We are a registered Ugandan
          Company under the company registrations Act, 2012 of the republic of
          Uganda. We Streamline and elevate business processes through
          innovation of Robust distribution solutions, integrated payment and
          merchant solutions, Mobile money aggregation, USSD codes providing
          customized and personalized transactional channel for businesses all
          across their country of operation.
        </p>

        <p className="my-6">
          We, in partnership with the major telecommunications companies and
          banks in Uganda have successfully masterminded transactions in
          millions of shillings for and on behalf of our happy customers,
          ensuring convenient access of goods and services.
        </p>

        <p>
          We also believe in a symbiotic business approach. We observed that
          synthesizing the above-mentioned services with financial management
          solutions, ICT advisory and training and general digital
          transformation of day to day business activities can help
          organizations of all levels streamline, optimize and boost the general
          organizational performance. “We are the home of adjoining business
          solutions anytime any day”.
        </p>
      </div>
    </div>
  );
}

export default AboutCompany;
