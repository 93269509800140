import React from "react";
import about from "../../assets/images/about-us.webp";
import { Slide } from "react-awesome-reveal";

function WhoWeAre() {
  return (
    <div className="flex flex-col-reverse md:flex-row">
      <div className="w-full py-10 md:py-14 main-bg">
        <Slide triggerOnce direction="left">
          <div className="flex flex-col items-center justify-center h-full max-w-xl p-8 mx-auto text-center text-white">
            <h1 className="text-3xl md:text-4xl text-white font-[300]">
              Who we are
            </h1>
            <h2 className="mt-2 text-4xl font-bold green-color md:text-5xl">
              What we stand for
            </h2>

            <p className="mt-5 text-lg leading-loose tracking-wide">
              The volatility in the financial landscape presents myriads of
              challenges for different parties involved, both service providers
              and the consumers of the financial services. The speed of
              technological evolution and ground breaking innovations demands that
              every business, small or big, embraces technology to create value
              for their businesses.
            </p>
          </div>
        </Slide>
      </div>
      <div className="w-full">
        <img src={about} alt="" className="object-cover w-full h-full" />
      </div>
    </div>
  );
}

export default WhoWeAre;
