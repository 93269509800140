import React from "react";

import { FaGlobeAfrica } from "react-icons/fa";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";

import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { google_map_api } from "../../utils/utils";
import { Fade, Slide } from "react-awesome-reveal";

function GetInTouch() {
  const [libraries] = React.useState(["places"]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: google_map_api,
    libraries: libraries,
  });

  const input_class = "w-full rounded-sm p-3 mb-3 mt-2";

  const icon_class = "text-6xl";

  const contacts = [
    {
      icon: <FaGlobeAfrica className={icon_class} />,
      title: "Address",
      detail: (
        <p>
          Ruthent Tower, 4th Floor, Plot 96A - Bukoto street, Kampala, Uganda
        </p>
      ),
    },
    {
      icon: <IoPhonePortraitOutline className={icon_class} />,
      title: "Call Us",
      detail: <a href="tel:+256200902247">+256 20 0902247</a>,
    },
    {
      icon: <MdOutlineMailOutline className={icon_class} />,
      title: "Email",
      detail: (
        <a href="mailto:helpdesk@jeestreams.com">helpdesk@jeestreams.com</a>
      ),
    },
  ];

  const center = {
    lat: 0.3431621926519102,
    lng: 32.592209885589675,
  };

  return (
    <div className="main-bg">
      <div className="grid gap-10 py-20 mx-auto text-white lg:grid-cols-2 container-box">
        <Slide triggerOnce direction="left">
          <div>
            <h3 className="text-5xl">
              Get in <span className="font-semibold green-color">Touch</span>
            </h3>
            <p className="mt-8 text-lg">
              Our dedicated customer support team is ready to assist you with any
              inquiries or concerns
            </p>
            <p className="my-3 text-lg">
              For partnership opportunities, collaborations, or any
              business-related inquiries
            </p>

            <div className="text-sm">
              <div>
                <p>Your name</p>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className={input_class}
                />
              </div>

              <div>
                <p>Email Address</p>
                <input
                  type="text"
                  placeholder="Enter email address"
                  className={input_class}
                />
              </div>

              <div>
                <p>Subject</p>
                <input
                  type="text"
                  placeholder="Enter subject"
                  className={input_class}
                />
              </div>

              <div>
                <p>Message</p>
                <textarea
                  placeholder="Message"
                  className={input_class}
                  rows={6}
                ></textarea>
              </div>

              <button className="px-16 py-3 text-black bg-gray-300 rounded-sm">
                Submit
              </button>
            </div>
          </div>
        </Slide>

        <Fade triggerOnce>
          <div>
            {isLoaded && (
              <GoogleMap
                zoom={15}
                center={center}
                mapContainerStyle={{ width: "100%", height: "450px" }}
              >
                <Marker
                  shape="MarkerShapePoly"
                  cursor="pointer"
                  position={center}
                />
              </GoogleMap>
            )}

            <div className="grid w-full gap-8 mt-10 md:grid-cols-3">
              {contacts.map((contact, i) => (
                <div key={i} className="text-center">
                  <div className="flex justify-center">{contact.icon}</div>
                  <h4 className="mt-6 mb-3 font-semibold green-color">
                    {contact.title}
                  </h4>
                  {contact.detail}
                </div>
              ))}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default GetInTouch;
