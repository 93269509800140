import React from "react";
import { Parallax } from "react-parallax";
import contact from "../../assets/images/contact.webp";
import GetInTouch from "./get-in-touch";
import { Helmet } from "react-helmet";
import { Slide } from "react-awesome-reveal";

function Contact() {
  const page = {
    title: "Contact us",
    description: "Our dedicated customer support team is ready to assist you with any inquiries or concerns. For partnership opportunities, collaborations, or any business-related inquiries"
  }
  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <Parallax
        blur={1}
        bgImage={contact}
        bgImageAlt="Testimonials"
        strength={300}
      >
        <div className="grid py-16 mx-auto lg:grid-cols-2 md:py-24 container-box ">
          <div></div>
          <Slide direction="right" triggerOnce>
            <div className="max-w-2xl px-4">
              <h2 className="text-3xl md:text-6xl font-[300]">Your ideas</h2>
              <p className="mt-2 text-5xl md:text-7xl font-[550] green-color">
                our solution
              </p>

              <p className="mt-4 text-xl">
                Contact us and we will put your ideas into action.
              </p>
            </div>
          </Slide>
        </div>
      </Parallax>

      <GetInTouch />
    </div>
  );
}

export default Contact;
