import React from "react";
import support from "../../assets/images/support.webp";
import { Slide } from "react-awesome-reveal";

function Synergy() {
  return (
    <div className="grid gap-10 py-20 mx-auto md:grid-cols-2 container-box">

      <div className="flex items-center justify-center">
        <Slide triggerOnce direction="left">
          <img
            src={support}
            alt="We believe in a symbiotic business approach. We observed that
          synthesizing the above-mentioned services with financial management
          solutions, ICT advisory and training and general digital
          transformation of day to day business activities can help
          organizations of all levels streamline, optimize and boost the general
          organizational performance. “We are the home of adjoining business
          solutions anytime any day"
            className="max-h-80"
          />
        </Slide>
      </div>
      <div className="menu-color">
        <Slide triggerOnce direction="right">
          <h2 className="text-4xl font-[550]">
            The value of synergy, lies in merging processes
          </h2>
          <p className="mt-4 text-lg leading-8">
            We believe in a symbiotic business approach. <br />
            We observed that synthesizing the above-mentioned services with
            financial management solutions, ICT advisory and training and general
            digital transformation of day to day business activities can help
            organizations of all levels streamline, optimize and boost the general
            organizational performance. <br />
            "We are the home of adjoining business solutions anytime any day".
          </p>
        </Slide>
      </div>
    </div>
  );
}

export default Synergy;
