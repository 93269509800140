import "@fontsource/manrope"; // Defaults to weight 400
import "@fontsource/manrope/800.css"; // Specify weight
// import "@fontsource/manrope/400-italic.css"; // Specify weight and style

import "./App.css";
import Content from "./layout/content";

function App() {
  return (
    <div className="App">
      <Content />
    </div>
  );
}

export default App;
