import React from "react";
import { FaRegCompass, FaPeopleGroup } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa";

function StandFor() {
  const icon_class = "text-7xl green-color";
  const stand_for = [
    {
      icon: <FaRegCompass className={"text-6xl green-color"} />,
      title: "Mission",
      description:
        "To provide technology enabled solutions to individuals and businesses in our geographical areas of operation.",
    },
    {
      icon: <FaRegEye className={icon_class} />,
      title: "Vision",
      description:
        "To be the hallmark of seamless financial solutions, ensuring ubiquity in the use of secure payment channels for our customers.",
    },
    {
      icon: <FaPeopleGroup className={icon_class} />,
      title: "Core Values",
      description: "Timeliness, Transparency, Innovativeness, Responsiveness",
    },
  ];
  return (
    <div className="py-28 main-bg">
      <div className="mx-auto text-white container-box">
        <h2 className="text-5xl text-center font-[300]">
          What we <span className="font-semibold green-color">stand for</span>
        </h2>

        <div className="grid mt-10 lg:grid-cols-3 gap-14">
          {stand_for.map((s, i) => (
            <div key={i} className="flex items-center gap-6">
              <div> {s.icon}</div>
              <div>
                <h3 className="mb-3 text-lg font-semibold green-color">
                  {s.title}
                </h3>
                <p>{s.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StandFor;
