import React from "react";
import WhoWeAre from "./who-we-are";
import AboutCompany from "./about-company";
import StandFor from "./stand-for";
import Testimonial from "../home/testimonial";
import GetInTouch from "../home/get-in-touch";
import Location from "./location";
import { Helmet } from "react-helmet";

function About() {
  const page = {
    title: "About us",
    description: "We are one of the kind legally registered business entity optimistic about bringing secure transaction into fruition. We are a registered Ugandan Company under the company registrations Act, 2012 of the republic of Uganda. We Streamline and elevate business processes through innovation of Robust distribution solutions, integrated payment and merchant solutions, Mobile money aggregation, USSD codes providing customized and personalized transactional channel for businesses all across their country of operation."
  }

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <WhoWeAre />
      <AboutCompany />
      <StandFor />
      <Testimonial />
      <GetInTouch />
      <Location />
    </div>
  );
}

export default About;
