import React from "react";
import TopContent from "./top-content";
import Synergy from "./synergy";
import GetInTouch from "./get-in-touch";
import Testimonial from "./testimonial";
import { Helmet } from "react-helmet";

function Home() {
  const page = {
    title: "JEE Streams Investments Limited",
    description: "JEE Streams Inventments Limited (JSI LTD) Streamlines and elevate business processes through innovation of Robust distribution solutions, integrated payment and merchant solutions, Mobile money aggregation, USSD codes providing customized and personalized transactional channel for businesses all across their country of operation"
  }
  return (
    <div>
    <Helmet>
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
      </Helmet>
      <TopContent />
      <Synergy />
      <Testimonial />
      <GetInTouch />
    </div>
  );
}

export default Home;
