import React from "react";
import logo_detail from "../assets/images/logo-detail.png";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Drawer } from "antd";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMenu } from "react-icons/io5";

function Header() {
  const [scrolled, setScrolled] = React.useState(false);
  const [active, setActive] = React.useState("");

  const menus = [
    { label: "Home", url: "", sub_links: [] },
    {
      label: "Our services",
      url: "services",
      sub_links: [
        { label: "Integrated payment and merchant solutions", url: "payments" },
        { label: "Mobile Money Aggregation", url: "mobile-money" },
        { label: "USSD code", url: "ussd" },
      ],
    },
    { label: "About us", url: "about", sub_links: [] },
    { label: "Contact us", url: "contact", sub_links: [] },
  ];

  React.useEffect(() => {
    const onScroll = () => setScrolled(window.pageYOffset > 80);

    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  let location = useLocation();
  React.useEffect(() => {
    setActive(location.pathname.substring(1));
    window.scrollTo({ behavior: "smooth", top: 0 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


  const [open, setOpen] = React.useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`z-10 transition-all duration-300 transform sticky top-0 bg-white  w-full`}
    >
      <div className="container flex items-center justify-between mx-auto">
        <img src={logo_detail} alt="JeeStreams Logo" className="h-14 my-1.5" />
        <div className="hidden gap-4 md:flex">
          {menus.map((menu, i) => {
            if (menu.sub_links.length > 0)
              return (
                <Dropdown
                  key={i}
                  menu={{
                    items: menu.sub_links.map((l, i) => {
                      return {
                        label: <Link to={`/services#${l.url}`}>{l.label}</Link>,
                        key: i,
                      };
                    }),
                  }}
                >
                  <Link key={i} to={`/${menu.url}`}>
                    <div className="flex items-center h-full gap-1 p-4">
                      <p
                        className={`font-medium ${active === menu.url ? "font-semibold active-link" : ""
                          }`}
                      >
                        {menu.label}
                      </p>
                      <IoMdArrowDropdown />
                    </div>
                  </Link>
                </Dropdown>
              );
            return (
              <Link key={i} to={`/${menu.url}`}>
                <div className="flex items-center h-full">
                  <p
                    className={`p-4 font-medium ${active === menu.url ? "font-semibold active-link" : ""
                      }`}
                  >
                    {menu.label}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="md:hidden">
          <IoMenu className="text-black text-4xl cursor-pointer" onClick={showDrawer} />
        </div>
      </div>

      <Drawer title="JEE Streams Investments Limited" onClose={onClose} open={open}>
        <div>
          {menus.map((menu, i) => {
            if (menu.sub_links.length > 0)
              return (
                <Dropdown
                  key={i}
                  menu={{
                    items: menu.sub_links.map((l, i) => {
                      return {
                        label: <Link to={`/services#${l.url}`}>{l.label}</Link>,
                        key: i,
                      };
                    }),
                  }}
                >
                  <Link key={i} to={`/${menu.url}`} onClick={onClose}>
                    <div className="flex items-center h-full gap-1 p-2">
                      <p
                        className={`font-medium ${active === menu.url ? "font-semibold green-color" : ""
                          }`}
                      >
                        {menu.label}
                      </p>
                      <IoMdArrowDropdown />
                    </div>
                  </Link>
                </Dropdown>
              );
            return (
              <Link key={i} to={`/${menu.url}`} onClick={onClose}>
                <div className="flex items-center">
                  <p
                    className={`p-2 font-medium ${active === menu.url ? "font-semibold green-color" : ""
                      }`}
                  >
                    {menu.label}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
