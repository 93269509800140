exports.social_links = {
  facebook: "https://www.facebook.com/61556901651970",
  linked_in: "https://www.linkedin.com/company/jee-streams-investments-limited/",
  twitter: "https://twitter.com/jeestreams",
  instagram: "https://www.instagram.com/jee.streams/",
  email: "mailto:helpdesk@jeestreams.com",
  phone: "https://wa.me/256200902247",
};

exports.google_map_api = "AIzaSyB0dy46oTvw9PivnuoTzy_aa5LDp_8FNIo";
